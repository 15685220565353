
import ServicePopup from "@/components/ServicePopup";
import wxShare from "@/wechat/weChatTile";
import {Toast} from "vant";
import {formatDate} from "@/utils/util";

export default {
    components: {
        ServicePopup
    },
    data() {
        return {
            detailData: {},
            kefuShow: false,
        }
    },
    async created() {
        let res = await axios.get("/qykshop/get_order_info", {
            params: {
                sn: this.$route.params.id
            }
        })
        if (res?.code === 1) {
            if (res.data) {
                this.detailData = res.data
                wxShare({
                    title: res.data.goods_title,
                    link: window.location.origin + '/ydj/detail/' + res.data.goods_id,
                    imgUrl: res.data.goods_pic
                })
            } else {

            }
        }
    },
    filters:{
        setTime(value) {
            return formatDate(value, 'YYYY年MM月DD日 hh:mm:ss')
        }
    }
}
